<template>
  <base-product-table
    :post-pas="postPas"
    :post-mydb="postMydb"
    @click="onClickEvent"
  ></base-product-table>
</template>
<script>
import BaseProductTable from "@/components/Bases/BaseProductTable";
export default {
  name: "ProductTable",
  components: { BaseProductTable },
  props: {
    postPas: {
      type: Array,
    },
    postMydb: {
      type: Function,
    },
  },
  methods: {
    onClickEvent(data) {
      //リレーションリンクを作成
      const relationlinks = [];
      this.postMydb({
        modelgroup: data.modelgroup,
        destination: data.destination,
      })
        .order("releasedate desc")
        .each((r) => {
          relationlinks.push(r);
        });
      window.localStorage.setItem(
        "relation_" + data.id,
        JSON.stringify(relationlinks)
      );
      const resolvedRoute = this.$router.resolve({
        name: "ModelClass",
        query: { id: data.id, r_id: data.id },
      });
      window.open(resolvedRoute.href);
    },
  },
};
</script>
