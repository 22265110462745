<template>
  <v-navigation-drawer
    app
    :value="drawer"
    :clipped="$vuetify.breakpoint.lgAndUp"
    @input="$emit('update:drawer', $event)"
  >
    <v-list dense>
      <v-list-item link router to="/">
        <v-list-item-action>
          <v-icon>mdi-chart-areaspline </v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>ホーム</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item link router to="/pa">
        <v-list-item-action>
          <v-icon>mdi-folder-multiple-image </v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>製品写真・他社商計</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-divider></v-divider>
      <v-list-item class="mt-4" link href="../pdf/glaph_help.pdf" target="help">
        <v-list-item-action>
          <v-icon>mdi-help-circle-outline </v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>グラフヘルプ</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item link href="../pdf/help.pdf" target="help">
        <v-list-item-action>
          <v-icon>mdi-help-circle-outline </v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>製品写真・他社商計ヘルプ</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
export default {
  props: {
    drawer: {
      type: Boolean,
    },
  },
};
</script>

