<template>
  <v-app>
    <v-main class="blue-grey lighten-5">
      <transition name="fade">
        <router-view />
      </transition>
    </v-main>
  </v-app>
</template>
<script>
import {UPDATE_OPTIONS_KEY} from "vue-echarts";

export default {
  name: "App",
  provide:  { [UPDATE_OPTIONS_KEY]: {notMerge: true, lazyUpdate: true} },
};
</script>
