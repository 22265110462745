import * as d3 from "d3";
import { Data } from "@/assets/types/Data";

export type SortType = { algorithm: "alphabetic" | "carnumb"; asc: boolean };
type SortableDataKey = "w_category" | "maker" | "model" | "f_category";

export function sortDataKey(data: Data[], key: SortableDataKey, sortType?: SortType) {
  const rollups = d3.rollups(
    data,
    (v) => d3.sum(v, (d) => d.total),
    (d) => d[key],
  );

  let sorted: typeof rollups;
  if (sortType.algorithm === "carnumb") {
    sorted = rollups.sort((a, b) => a[1] - b[1]);
  } else {
    sorted = rollups.sort((a, b) => a[0].localeCompare(b[0]));
  }
  const keys = sorted.map((d) => d[0]);
  return sortType.asc ? keys : keys.reverse();
}
