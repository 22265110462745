import * as d3 from "d3";
import { ActionTree, MutationTree, GetterTree } from "vuex";
import { Data } from "@/assets/types/Data";
import { GraphApiOptions, Query } from "@/assets/types/Query";
import END_POINT from "@/config/endpoint";
import { RootState } from "@/store";
import { UrlSearchQuery } from "@/js/utils";

export type Config = {
  viewMode: string;
  query: {
    country: string | null;
    w_category: string | null;
    maker: string | null;
  };
};

const state = () => ({
  modelEditHistory: {} as Data.ModelEditHistory,
  tableGraphWithoutEuro: [],
  tableGraphData: [],
  tableGraphDataModels: [],
  tableGraphDataModelsCountry: null,
  viewMode: "country",
  query: { country: null, maker: null, w_category: null },
  withoutEuro: false,
});

type State = ReturnType<typeof state>;

export type GraphQuery = Query<Data | Data.Formatted>;

const filter = (query: Config["query"], datas: Data[]) => {
  return d3.filter(
    datas,
    (data) =>
      (!query.country || data.country === query.country) &&
      (!query.maker || data.maker === query.maker) &&
      (!query.w_category || data.w_category === query.w_category),
  );
};

const getters: GetterTree<State, RootState> = {
  graphData: ({ tableGraphWithoutEuro, tableGraphData, tableGraphDataModels, viewMode, withoutEuro }) => {
    if (viewMode === "model") {
      return tableGraphDataModels;
    } else if (withoutEuro) {
      return tableGraphWithoutEuro;
    }
    return tableGraphData;
  },
  filteredGraphData: ({ query }, { graphData }) => filter(query, graphData),
  modelEditHistory: ({ modelEditHistory }) => modelEditHistory,
  config: ({ viewMode, query, withoutEuro }) => ({ viewMode, query, withoutEuro }),
  withouEuro: ({ withoutEuro }) => withoutEuro,
};
const actions: ActionTree<State, RootState> = {
  resetConfig({ dispatch }) {
    const query: Config["query"] = { country: null, w_category: null, maker: null };
    const viewMode = "country";
    const defaultConfig: Config = { query, viewMode };
    dispatch("setConfig", defaultConfig);
  },
  async setConfig({ state, commit, dispatch }, config: Config) {
    if (config.viewMode === "model" && config.query.country !== state.tableGraphDataModelsCountry) {
      await dispatch("fetchTableGraphModels", { c: [config.query.country] });
    }
    commit("setConfig", config);
  },
  async init({ dispatch }) {
    const promise = dispatch("fetchTableGraphWithoutEuro");
    const promise2 = dispatch("fetchTableGraph");
    await Promise.all([promise, promise2]);
  },
  async fetchTableGraphWithoutEuro({ commit }) {
    const data = await d3.tsv(`${END_POINT}?sum=true&EUR=false`);
    if (data.length === 0) return;
    ((data as any[]) as Data[]).forEach((d) => {
      d.year = Number(d.year);
      d.cc = Number(d.cc);
      Object.freeze(d);
    });
    commit("setTableGraphWithoutEuro", data);
  },
  async fetchTableGraph({ commit }) {
    const data = await d3.tsv(`${END_POINT}?sum=true`);
    if (data.length === 0) return;
    ((data as any[]) as Data[]).forEach((d) => {
      d.year = Number(d.year);
      d.cc = Number(d.cc);
      Object.freeze(d);
    });
    commit("setTableGraphData", data);
  },
  async fetchTableGraphModels({ commit }, options: GraphApiOptions) {
    const searchParam = new UrlSearchQuery(options);
    const data = await d3.tsv(`${END_POINT}?${searchParam.toString()}&short=true`);
    if (data.length === 0) return;
    ((data as any[]) as Data[]).forEach((d) => {
      d.year = Number(d.year);
      d.cc = Number(d.cc);
      Object.freeze(d);
    });
    commit("setTableGraphDataModels", data);
  },
  setWithoutEuro({ commit }, value) {
    commit("setWithoutEuro", value);
  },
};

const mutations: MutationTree<State> = {
  setWithoutEuro(state, value) {
    state.withoutEuro = value;
  },
  setConfig(state, config: Config) {
    state.viewMode = config.viewMode;
    state.query = config.query;
  },
  updateModelEditHistory(state, { modelName, origin = null, newModel = null }) {
    // if already saved origin model then only update newModel
    state.modelEditHistory = state.modelEditHistory[modelName]
      ? {
          ...state.modelEditHistory,
          [modelName]: {
            ...state.modelEditHistory[modelName],
            new: newModel,
          },
        }
      : {
          ...state.modelEditHistory,
          [modelName]: {
            origin,
            new: newModel,
          },
        };
  },
  clearModelEditHistory(state) {
    state.modelEditHistory = {};
  },
  setTableGraphWithoutEuro(state, data) {
    state.tableGraphWithoutEuro = data;
  },
  setTableGraphData(state, data) {
    state.tableGraphData = data;
  },
  setTableGraphDataModels(state, data) {
    state.tableGraphDataModels = [...state.tableGraphDataModels, ...data];
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
