export default Object.freeze({
  url:
    (location.hostname === "localhost" ? "http://" : "https://").toString() +
    location.hostname +
    "/",
  storage: "hogefoo",
  debounceDelay: {
    min: 250,
    medium: 500,
    max: 1000,
  },
  fields: [
    {
      label: "モデルイヤー",
      id: "modelyear",
      type: "tr",
    },
    {
      label: "メーカー",
      id: "maker",
      type: "tr",
    },
    {
      label: "カテゴリ",
      id: "category",
      type: "tr",
    },
    {
      label: "モデル名",
      id: "modelname",
      type: "tr",
    },
    {
      label: "仕向け地（コード）",
      id: "destination",
      type: "tr",
    },
    {
      label: "排気量",
      id: "cc",
      type: "tr",
    },
    {
      label: "通貨単位",
      id: "c_type",
      type: "tr",
    },
    {
      label: "価格",
      id: "price",
      type: "tr",
    },
    {
      label: "色数",
      id: "note",
      type: "tr",
    },
    {
      label: "立上／発売年月日",
      id: "releasedate",
      type: "tr",
    },
    {
      label: "打切年月日",
      id: "deadline",
      type: "tr",
    },
    {
      label: "備考",
      id: "memo",
      type: "tr",
    },
    {
      id: "eg",
      color: "deep-purple",
      type: "tip",
    },
    {
      id: "bd",
      color: "teal",
      type: "tip",
    },
    {
      id: "style",
      color: "amber",
      type: "tip",
    },
    {
      id: "cg",
      color: "brown",
      type: "tip",
    },
    {
      id: "el",
      color: "pink",
      type: "tip",
    },
  ],
  protocol_name: "https://cova.sakuraweb.com",
});

export const makerColors = {
  "(MOFAZ-MOMOS)": "#5470C6",
  ADIVA: "#91cc75",
  AEON: "#E31A22",
  AJ: "#ee6666",
  AKT: "#1B4C92",
  APRILIA: "#FFC000",
  BAGON: "#fc8452",
  BAJAJ: "#92D050",
  BENELLI: "#336600",
  BIGBULL: "#5470c7",
  BIMOTA: "#91cc76",
  BKZ: "#fac859",
  BMW: "#BFBFBF",
  BOMBARDIER: "#E03C31",
  BRAMONT: "#3ba273",
  BRIXTON: "#fc8453",
  BRP: "#9a60b5",
  BSA: "#ea8ccc",
  BUELL: "#050606",
  CAGIVA: "#91cc77",
  CHAIYO: "#fac860",
  CHEVROLET: "#ee6668",
  China: "#DE2910",
  CIVICA: "#3ba274",
  COMEL: "#fc8454",
  CPI: "#9a60b6",
  DAELIM: "#ea9ccc",
  DAFRA: "#FF66FF",
  DAGON: "#91cc78",
  DEMAK: "#32457A",
  DUCATI: "#33CCFF",
  EAGLE: "#73c3de",
  "East World": "#3ba275",
  "East World / Motorstar": "#fc8455",
  "E-BIKE": "#9a60b7",
  ENFIELD: "#DA291C",
  EVT: "#5470c1",
  GARUDA: "#91cc79",
  GILERA: "#fac862",
  GOGORO: "#33CC33",
  GPX: "#E92026",
  "GREEN PEAK": "#3ba276",
  GUSTO: "#fc8456",
  HARDE: "#9a60b8",
  "HARLEY DAVIDSON": "#FA6600",
  HERO: "#0099FF",
  HONDA: "#FF0000",
  HUSABERG: "#ee6671",
  HUSQVARNA: "#002550",
  HYOSUNG: "#3ba277",
  INDIAN: "#00CCFF",
  JESPA: "#9a60b9",
  JIALING: "#ea12cc",
  JIANGSU: "#5470c1",
  JRD: "#91cc81",
  JTZ: "#1D1C1A",
  KANZEN: "#ee6672",
  KAWASAKI: "#92D050",
  "KEEWAYS BENELLI": "#3ba278",
  KINETIC: "#FFAE20",
  KTM: "#ED7D31",
  KTN: "#ea13cc",
  KUBOTA: "#5470c1",
  KYMCO: "#FFFF66",
  LAMBRETTA: "#fac865",
  LEXUR: "#ee6673",
  LIFAN: "#C90D26",
  LML: "#024D92",
  "LUCKY LION": "#fc8459",
  "M BIKE": "#9a60b1",
  "MALAYSIAN FORMULA BIKES S/B": "#ea14cc",
  MCX: "#5470c1",
  "MLE-MOTOR LEGEND ENTERP": "#91cc83",
  MODENAS: "#002169",
  MOFAZ: "#ee6674",
  "MOFAZ-CHEAR GLOBAL SDN BHD": "#73c8de",
  "MOFAZ-SARAWAK HUAT MENG MOTOR & MB MOTOR": "#3ba280",
  MOMOS: "#9a60b1",
  "MOTO-GUZZI": "#C8A16A",
  "MOTOR LEGEND ENTERPRISE": "#fac867",
  "MV AGUSTA": "#A31514",
  MZ: "#fc8461",
  "N.B MOTOR TRADING": "#9a60b1",
  "N.B MOTORS TRADING": "#ea16cc",
  NAKARIEST: "#5470c1",
  NAZA: "#91cc85",
  NIMOTA: "#fac868",
  "NIMOTA USE MOFAZ RITZ AP": "#ee6676",
  NITRO: "#73c10d",
  NIU: "#CC0000",
  NKT: "#fc8462",
  Other: "#9a60b1",
  OTHERS: "#ea17cc",
  "OTRAS MARCAS": "#5470c1",
  PEUGEOT: "#000000",
  PIAGGIO: "#0099FF",
  "PIAGGIO/VESPA": "#6CC4AB",
  PLATINUM: "#3ba283",
  POLARIS: "#fc8463",
  "ROYAL ENFIELD": "#DA291C",
  RYUKA: "#ea18cc",
  SACHS: "#5470c1",
  SANYANG: "#FF99FF",
  "SARAWAK HUAT MENG": "#fac870",
  "SARAWAK HUAT MENG MOTOR & MB MOTOR": "#ee6678",
  SKYWING: "#73c12d",
  STALLION: "#F6121C",
  SUN: "#fc8464",
  SUZUKI: "#FFFF00",
  SYM: "#E00521",
  "T-BIKE": "#5470c1",
  TIGER: "#91cc88",
  TOYOTA: "#fac871",
  TOYOTRON: "#ee6679",
  TRAXX: "#73c13d",
  TRIUMPH: "#7030A0",
  TVS: "#FF9900",
  UDA: "#9a60b1",
  VESPA: "#ea20cc",
  VICTORY: "#DD2000",
  VIVACE: "#91cc89",
  XFG: "#fac872",
  XINGFU: "#ee6680",
  YADEA: "#73c14d",
  YAMAHA: "#0000FF",
  "ZERO ENGINEERING": "#fc8466",
  安普工業: "#9a60b1",
  易維特: "#ea21cc",
  易速達: "#5470c2",
  益通: "#91cc90",
  可愛馬: "#fac873",
  冠美: "#ee6681",
  合騏: "#73c15d",
  松營: "#3ba287",
  鉦尚: "#fc8467",
  其易: "#9a60b1",
  其他: "#ea22cc",
  中華: "#5470c2",
  鼎力: "#91cc91",
  杜卡動力: "#fac874",
  東庚: "#ee6682",
  同喬: "#73c16d",
  美家園: "#3ba288",
  美博士: "#fc8468",
  必翔: "#9a60b2",
  摩特動力: "#CC3300",
  摩力美: "#5470c2",
  陽光: "#91cc92",
  亞電: "#fac875",
  哈特佛: "#ee6683",
  爬山王: "#73c17d",
  綠鑽: "#3ba289",
  錡明: "#fc8469",
} as const;

export const wCategoryColors = {
  Cruiser: '#5470c6',
  'Dual purpose': '#fac858',
  EV: '#91cc75',
  Moped: '#ee6666',
  'Off/Compe': '#73c0de',
  Scooter: '#3ba272',
  Sport: '#fc8452',
  Others: '#9a60b4',
  CP: '#73c0de',
  CR: '#5470c6',
  DP: '#FAC858',
  MP: '#ee6666',
  SC: '#3ba272',
  SP: '#fc8452',
  SS: '#9966FF',
  ST: '#D60093',
} as const

export const pickerColors = [
  ["#5470c6", "#3ba272", "#AA0000"],
  ["#91cc75", "#fc8452", "#550000"],
  ["#fac858", "#9a60b4", "#FFFF00"],
  ["#ee6666", "#ea7ccc", "#AAAA00"],
  ["#73c0de", "#FF0000", "#555500"],
] as const;

export const engines = [
  { from: null, to: 50, text: '50㏄以下' },
  { from: 51, to: 125, text: '51~125cc' },
  { from: 126, to: 200, text: '126~200cc' },
  { from: 201, to: 500, text: '201~500cc' },
  { from: 501, to: 950, text: '501~950cc' },
  { from: 951, to: null, text: '951㏄以上' },
] as const

export const countries = [
  { value: 'EUR', text: 'EUR' },
  { value: 'ARG', text: 'ARG' },
  { value: 'BGD', text: 'BGD' },
  { value: 'BRA', text: 'BRA' },
  { value: 'CAN', text: 'CAN' },
  { value: 'CHN', text: 'CHN' },
  { value: 'COL', text: 'COL' },
  { value: 'IDN_R', text: 'IDN_R' },
  { value: 'IDN', text: 'IDN' },
  { value: 'IND', text: 'IND' },
  { value: 'JPN', text: 'JPN' },
  { value: 'NPL', text: 'NPL' },
  { value: 'MYS', text: 'MYS' },
  { value: 'PAK', text: 'PAK' },
  { value: 'PHL', text: 'PHL' },
  { value: 'THA', text: 'THA' },
  { value: 'TWN', text: 'TWN' },
  { value: 'USA', text: 'USA' },
  { value: 'VNM', text: 'VNM' },
] as const

export const euCountries = [
  { value: "AUSTRIA", text: "AUSTRIA" },
  { value: "BELGIUM", text: "BELGIUM" },
  { value: "CROATIA", text: "CROATIA" },
  { value: "DENMARK", text: "DENMARK" },
  { value: "ENGLAND", text: "ENGLAND" },
  { value: "ESTONIA", text: "ESTONIA" },
  { value: "FINLAND", text: "FINLAND" },
  { value: "FRANCE", text: "FRANCE" },
  { value: "GERMANY", text: "GERMANY" },
  { value: "GREECE", text: "GREECE" },
  { value: "HOLLAND", text: "HOLLAND" },
  { value: "HUNGARY", text: "HUNGARY" },
  { value: "IRELAND", text: "IRELAND" },
  { value: "ITALY", text: "ITALY" },
  { value: "LATVIA", text: "LATVIA" },
  { value: "LUXEMBOURG", text: "LUXEMBOURG" },
  { value: "LITHUANIA", text: "LITHUANIA" },
  { value: "NORWAY", text: "NORWAY" },
  { value: "POLAND", text: "POLAND" },
  { value: "PORTUGAL", text: "PORTUGAL" },
  { value: "SLOVAKIA", text: "SLOVAKIA" },
  { value: "SLOVENIA", text: "SLOVENIA" },
  { value: "SPAIN", text: "SPAIN" },
  { value: "SWEDEN", text: "SWEDEN" },
  { value: "SWITZERLAN", text: "SWITZERLAND" },
] as const;

export const countryCurrencyMap = {
  AUSTRIA: "EUR",
  BELGIUM: "EUR",
  BRA: "BRL",
  CAN: "CAD",
  SWITZERLAN: "CHF",
  CHN: "CNY",
  COL: "COP",
  DENMARK: "DKK",
  ENGLAND: "GBP",
  FINLAND: "EUR",
  FRANCE: "EUR",
  GERMANY: "EUR",
  GREECE: "EUR",
  HOLLAND: "EUR",
  CROATIA: "HRK",
  HUNGARY: "HUF",
  IRELAND: "EURO",
  IDN: "IDR",
  IDN_R: "IDR",
  IND: "INR",
  ITALY: "EUR",
  JPN: "JPY",
  LUXEMBOURG: "EUR",
  NORWAY: "NOK",
  PHL: "PHP",
  POLAND: "PLN",
  PORTUGAL: "EUR",
  SWEDEN: "SEK",
  SLOVAKIA: "EUR",
  SLOVENIA: "EUR",
  SPAIN: "EUR",
  THA: "THB",
  TWN: "TWD",
  USA: "USD",
  VNM: "VND",
} as const;

export const variableTitle = [
  { text: "地域を選択", icon: "mdi-map-marker" },
  { text: "カテゴリを選択", icon: "mdi-shape-plus" },
  { text: "モデルを選択", icon: "mdi-compare" },
  { text: "メーカーを選択", icon: "mdi-motorbike" },
  { text: "排気量を選択", icon: "mdi-engine" },
  { text: 'EVを選択', icon: 'mdi-engine' },
  { text: "期間を選択", icon: "mdi-calendar" },
  { text: "価格を選択", icon: "mdi-currency-usd" },
] as const;

export const chartTitle = [
  { text: "bar chart", icon: "mdi-poll" },
  { text: "stack bar chart", icon: "mdi-chart-bar-stacked" },
  { text: "multi line chart", icon: "mdi-chart-multiline" },
  { text: "面積 chart", icon: "mdi-chart-tree" },
  { text: "bubble chart", icon: "mdi-chart-scatter-plot" },
  { text: "移動累計", icon: "mdi-chart-timeline-variant" },
  { text: "百分率", icon: "mdi-chart-arc" },
  { text: "temp", icon: "mdi-chart-areaspline" },
  { text: "ranking", icon: "mdi-star" },
] as const;

export function calcPath(protocol_name, path, filename) {
  return protocol_name + "/data" + path.replace(/%20/g, "%2520") + filename;
}
export const getKeys = (data, name) => getUniq(data.map((e) => e[name]));
const getUniq = (array) => [...new Set(array)].sort();
