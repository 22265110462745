import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";

Vue.use(VueRouter);

const routes = [{
    path: "/",
    name: "Graphs",
    meta: {
      title: "グラフ",
    },
    component: () => import("../views/Graphs.vue")
  },
  {
    path: "/pa",
    name: "ProductTable",
    component: Home,
  },
  {
    path: "/thumb/",
    name: "Thumb",
    component: () => import("../views/Thumb.vue"),
  },
  {
    path: "/related/",
    name: "Related",
    component: () => import("../views/RelatedUnits.vue"),
  },
  {
    path: "/modelname/",
    name: "ModelName",
    component: () => import("../views/ModelName.vue"),
  },
  {
    path: "/modellink/",
    name: "ModelLink",
    component: () => import("../views/ModelLink.vue"),
  },
  {
    path: "/model",
    name: "ModelClass",
    component: () => import("../views/ModelClass.vue"),
  },
  {
    path: "/signin",
    name: "Signin",
    meta: {
      title: "サインイン",
      isPublic: true,
    },
    component: () => import("../views/Signin.vue"),
  },
  {
    path: "/password",
    name: "Password",
    meta: {
      title: "パスワード設定",
      isPublic: true,
    },
    component: () => import("../views/ChangePassword.vue"),
  },
];

const router = new VueRouter({
  routes,
});

export default router;