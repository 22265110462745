import * as d3 from "d3";
import { ActionTree, MutationTree, GetterTree } from "vuex";
import { ProductLink } from "@/assets/types/ProductLink";
import END_POINT from "@/config/endpoint";
import { RootState } from "@/store";
import { UrlSearchQuery } from "@/js/utils";

const state = () => ({
  data: [] as ProductLink[],
});

type State = ReturnType<typeof state>;

const getters: GetterTree<State, RootState> = {
  data: ({ data }) => data,
};
const actions: ActionTree<State, RootState> = {
  async init({ commit }, countries) {
    const options = { c: countries };
    const searchParam = new UrlSearchQuery(options).toString();
    const url = `${END_POINT}?${searchParam}&links=true`;
    const data = await d3.tsv(url);
    if (data.length === 0) return;
    ((data as any[]) as ProductLink[]).forEach((d) => {
      Object.freeze(d);
    });
    commit("setData", data);
  },
};

const mutations: MutationTree<State> = {
  setData(state, value) {
    state.data = value;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
