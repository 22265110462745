import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import firebase from "firebase/app"
import { firebaseConfig } from "@/config"
import "firebase/auth"
import "./plugins/vee-validate"
import 'roboto-fontface/css/roboto/roboto-fontface.css'
import '@mdi/font/css/materialdesignicons.css'
import { ComponentOptions } from 'vue/types/umd'
import LocalStoragePlugin from '@/plugins/local-storage'

import ECharts from 'vue-echarts' // refers to components/ECharts.vue in webpack

// import ECharts modules manually to reduce bundle size
import 'echarts'
import 'echarts/lib/chart/bar'
import 'echarts/lib/component/tooltip'
// temp declare for plugin, to-do: add to .d.ts file
declare module 'vue/types/vue' {
  interface Vue {
    $localStorage: any;
  }
}

// register component to use
Vue.component('v-chart', ECharts)
Vue.use(LocalStoragePlugin, {
  cacheVersion: '4.0',
});
Vue.config.productionTip = false
firebase.initializeApp(firebaseConfig);
router.beforeResolve((to, from, next) => {
  const isPublic = to.matched.some((record) => record.meta.isPublic);
  document.title = to.meta.title + " | 競合他社投入商品分析";
  if (isPublic) {
    next();
    return;
  }
  firebase.auth().onAuthStateChanged((user) => {
    if (user) {
      next();
      return;
    }
    //ログインしてない
    next({
      path: "/signin",
    });
  });
});

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
} as ComponentOptions<Vue>).$mount('#app')
