import { SET_UNITS } from "./commits";

import { FETCH_UNITS, SET_CONDITION, WRITE_UNITS } from "./actions";

import * as d3 from "d3";
import firebase from "firebase/app";
import "firebase/database";

const state = () => ({
  data: [],
  unitsDb: [],
  year: null,
  maker: null,
  country: null,
  w_category: null,
  cc: null,
  model: null,
  id: null,
});
const getKeys = (data, name) => getUniq(data.map((e) => e[name]));
const getUniq = (array) => [...new Set(array)].sort();

const getters = {
  units: (state) => state.data,
  country: (state) => {
    return getKeys(state.data, "country");
  },
  w_category: (state) => {
    return getKeys(state.data, "w_category");
  },
  countries: (state) => {
    return getKeys(state.data, "country");
  },
  maker: (state) => {
    return getKeys(state.data, "maker");
  },
  year: (state) => {
    return getKeys(state.data, "year");
  },
  query: (state) => {
    return {
      year: state.year,
      country: state.country,
      cc: state.cc,
      maker: state.maker,
      w_category: state.w_category,
      model: state.model,
      id: state.id,
    };
  },
  unitsDb: (state) => state.unitsDb,
};

// actions
const actions = {
  async [FETCH_UNITS](context) {
    // const data = await d3.tsv("./data/ALLShort.txt");
    const data = await d3.tsv("https://cova.sakura.ne.jp/o/d2.php");
    context.commit(SET_UNITS, data);
    context.dispatch("fetchRelated");
  },
  fetchRelated(context) {
    const relatedUnitsRef = firebase.database().ref("/related-units");
    relatedUnitsRef.on("value", (snap) => {
      context.commit("setDb", snap.val());
    });
  },
  async [WRITE_UNITS](context, units) {
    const relatedUnitsRef = firebase.database().ref("/related-units");
    await relatedUnitsRef.update(units);
    context.commit("clearCondition");
  },
  [SET_CONDITION](context, query) {
    context.commit("setCondition", query);
  },
};

// mutations
const mutations = {
  [SET_UNITS](state, units) {
    state.data = units;
  },
  setDb(state, Db) {
    state.unitsDb = Db;
  },
  clearCondition(state) {
    state.year = null;
    state.maker = null;
    state.country = null;
    state.w_category = null;
    state.cc = null;
    state.model = null;
    state.id = null;
  },
  setCondition(state, query) {
    for (const [key, value] of Object.entries(query)) {
      state[key] = value;
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
