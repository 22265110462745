import * as d3 from 'd3'
import Vue from 'vue'
import Vuex from 'vuex'
import { Data } from '@/assets/types/Data'
import tableGraph from '@/store/tableGraph'
import colors from '@/store/modules/colors'
import viewSettings from '@/store/modules/viewSettings'
import links from '@/store/modules/links'
import units from '@/store/modules/units'
import END_POINT from '@/config/endpoint'
import { countries, engines, euCountries } from '@/js/const'
import { localStorage } from '@/plugins/local-storage'
import { convertCountryCurrencyToUSD } from '@/js/convert-currency'
import { UrlSearchQuery, deepEqual, dateFormat } from '@/js/utils'
import { StoreQueryType } from '@/assets/types/StoreQueryType'
import { sortDataKey, SortType } from '@/js/sortDataKey'
import { filterData } from '@/js/filterData'
import { FutureData } from '@/assets/types/FutureData'

Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'

export type RootState = {
  data: Data[]
  showMonth: boolean
  query: StoreQueryType
  loadedCountries: string[]
  wCategorySortType: SortType
  modelSortType: SortType
  makerSortType: SortType
}

const getDataKeys = <T extends keyof Data>(data: Data[], name: T) =>
  d3
    .groups(data, (d) => d[name])
    .filter((f) => f[0])
    .map(([key]) => key)

const store = new Vuex.Store<RootState>({
  state: () => ({
    data: [] as Data[],
    showMonth: false,
    loadedCountries: [],
    query: null as StoreQueryType,
    wCategorySortType: { algorithm: 'alphabetic', asc: true } as SortType,
    makerSortType: { algorithm: 'alphabetic', asc: true } as SortType,
    modelSortType: { algorithm: 'alphabetic', asc: true } as SortType,
  }),
  getters: {
    isFuture: (state) => state.query.future ?? false,
    formattedData: (_, getters) => (query: StoreQueryType) =>
      getters
        .data(query)
        .map((d) => Data.format(d))
        .flat(),

    data: ({ data }, getters) => (query: StoreQueryType) => {
      if (deepEqual(query, getters.query)) {
        return getters._cachedData
      } else {
        return filterData(data, query, getters._options)
      }
    },

    _cachedData: ({ data }, getters) => filterData(data, getters.query, getters._options),
    showMonth: (state) => state.showMonth,

    // ソートされてないキー。要リファクタリング
    wCategories: ({ data }) => [
      ...new Set(getDataKeys(data, 'w_category').concat(getDataKeys(data, 'f_category'))),
    ],
    manufacturers: ({ data }) => getDataKeys(data, 'maker'),
    models: ({ data }) => getDataKeys(data, 'model'),
    sortedWCategories: ({ data, wCategorySortType, query }) =>
      sortDataKey(data, query.future ? 'f_category' : 'w_category', wCategorySortType),
    sortedManufacturers: ({ data, makerSortType }) => sortDataKey(data, 'maker', makerSortType),
    sortedModels: ({ data, modelSortType }) => sortDataKey(data, 'model', modelSortType),

    engines: () => engines,
    evs: ({ data }) =>
      getDataKeys(data, 'kw').sort((a, b) =>
        Number(a.replace(/^(\d+).*$/, '$1')) -
        Number(b.replace(/^(\d+).*$/, '$1')),
      ),
    engineRange: () => [0, 9999],
    years: ({ data }) => {
      const [min, max] = d3.extent(getDataKeys(data, 'year'))
      return [min, max];
    },
    dates: ({ data }) => {
      const [min, max] = d3.extent(getDataKeys(data, 'year'))
      return [min + '-01', max + '-12']
    },
    countries: () => [...countries, ...euCountries],
    loadedCountries: ({ loadedCountries }) => loadedCountries,
    prices: ({ data }) => {
      const max = d3.max(getDataKeys(data, 'price'));
      const prices = []
      for (let i = 0; i < 5; i++) {
        const from = Math.round((max * i) / 6)
        const to = i < 4 ? Math.round((max * (i + 1)) / 6) - 1 : max + 1;
        const text = i === 0 ? `${to}以下` : i === 4 ? `${from}以上` : `${from}~${to}`
        prices.push({ from, to, text })
      }
      return prices
    },

    priceRange: () => [0, 99999],

    // TODO yearをcomputedにとりあえずしておくけど廃止したい
    query: (state, getters) => {
      const query = state.query ?? getters.defaultQuery
      const dateToYear = (d: string) => Number(d.split('-')[0])
      const date = query.date ?? getters.defaultQuery.date
      const year = date.map(dateToYear)
      const blankQuery: StoreQueryType = {
        w_category: getters.wCategories,
        maker: getters.manufacturers,
        model: getters.models,
        price: getters.prices,
        showPriceNull: true,
        showCcNull: true,
        showCc: true,
        showEv: true,
        cc: getters.engines,
        kw: getters.evs,
        date: getters.dates,
      }
      return { ...blankQuery, ...query, year, date }
    },

    defaultQuery: (_, getters) => {
      // TODO defaultをgettersにするのは混乱を生みやすいかもしれない。
      return {
        w_category: getters.wCategories,
        maker: getters.manufacturers,
        model: getters.models,
        countries: ['USA'],
        price: getters.prices,
        showPriceNull: true,
        showCcNull: true,
        cc: getters.engines,
        kw: getters.evs,
        date: getters.dates,

        showCc: true,
        showEv: true,
      }
    },

    _options: (_, getters) => {
      const { wCategories, manufacturers, models, engines, prices, evs } = getters
      const options: StoreQueryType = {
        cc: engines,
        kw: evs,
        price: prices,
        maker: manufacturers,
        w_category: wCategories,
        model: models,
      }
      return options
    },

    selectedCountries: ({ query }) =>
      !query
        ? ['']
        : query.countries.length === 0
        ? euCountries.map((c) => c.text)
        : query.countries.map(
            (c1) =>
              countries.find((c2) => c1 === c2.value)?.text ??
              euCountries.find((c2) => c1 === c2.value)?.text,
          ),
  },
  actions: {
    async setSelectedCountries({ commit, state }, countries: string[]) {
      const defaultCountries = euCountries.map((c) => c.value)
      countries = countries.length > 0 ? countries : defaultCountries
      const c = countries

      const options: { c: string[]; future?: boolean } = { c }
      if (state.query.future) {
        const euValues = euCountries.map<string>((d) => d.value)
        options.c = [...new Set(c.map((c) => (euValues.includes(c) ? 'EUR' : c)))]
        options.future = true
      }
      const searchParam = new UrlSearchQuery(options).toString()
      const url = `${END_POINT}?${searchParam}`
      commit('setLoadedCountries', options.c)
      const data = await d3.tsv(url)
      //console.log(data)
      commit('setData', data)
      commit('tableGraph/clearModelEditHistory')
    },

    updateShowMonth({ commit }, value) {
      commit('updateShowMonth', value)
    },

    async registerQuery(
      { dispatch, state, commit },
      q: { query: StoreQueryType; willCache: boolean },
    ) {
      const euValues = euCountries.map<string>((d) => d.value)
      // futureが選択されており選択地域にeu内の国があったらeu内の国を全選択する
      if (
        q.query.future &&
        state.query &&
        state.query.countries.some((d) => euValues.includes(d))
      ) {
        q.query.countries = [...new Set([...state.query.countries, 'EUR'])]
      }
      if (!q.query?.future && state.query?.countries?.includes?.('EUR')) {
        q.query.countries = [...new Set([...state.query.countries, 'EUR'])]
        q.query.countries = q.query.countries.flatMap(d => d === 'EUR' ? euValues : d)
      }
      if(!q.query?.future && q.query.countries.length===0){
        q.query.countries = euValues;
      }
      let shouldLoadData = !state.query || state.query.future !== q.query.future
      shouldLoadData = shouldLoadData
        ? true
        : !isSetEqual(q.query?.countries ?? [], state?.query?.countries ?? [])
      commit('registerQuery', q)
      if (shouldLoadData) {
        await dispatch('setSelectedCountries', q.query.countries)
      }
    },
  },
  mutations: {
    registerQuery(
      state,
      { query, willCache = true }: { query: StoreQueryType; willCache: boolean },
    ) {
      // 色のみ変更、みたいな時に再描画されないとかが起きるのでキャッシュを取るのは一旦無くす
      state.query = query
      if (willCache) {
        localStorage.set('graphQuery', query)
      }
    },

    setData(state, data: (Data | FutureData)[]) {
      // freeze the object so that Vue does not init Observer on every property
      // because graph data is not modified only filtered
      // this reduces memory usage and increase performance (you will see that the graph loads slightly faster)
      // Note: this will make changes to graph data not reactive.
      // if (data.length === 0) return
      const convertCurrency = state.loadedCountries.length > 1 && !state.query.future
      data.forEach((d) => Data.normalize(d, convertCurrency))
      state.data = data
    },
    clearData(state) {
      state.data = []
    },
    updateModelData(state, updatedModel) {
      // edit all records that have the same model
      state.data = state.data.map((item) =>
        item.model === updatedModel.name
          ? {
              ...item,
              ...updatedModel,
            }
          : item,
      )
    },
    updateShowMonth(state, value) {
      state.showMonth = value
      localStorage.set('showMonth', value)
    },
    setLoadedCountries(state, countries) {
      state.loadedCountries = countries
    },
    updateWCategorySortType(state, val) {
      state.wCategorySortType = val
    },
    updateModelSortType(state, val) {
      state.modelSortType = val
    },
    updateMakerSortType(state, val) {
      state.makerSortType = val
    },
  },
  modules: {
    tableGraph,
    viewSettings,
    colors,
    links,
    units,
  },
  strict: debug,
})

export default store

const isSetEqual = (value1: string[], value2: string[]): boolean => {
  const sorted1 = Array.from(value1).sort()
  const sorted2 = Array.from(value2).sort()
  console.log(sorted1, sorted2)
  return sorted1.every((v, i) => v === sorted2[i]) && sorted2.every((v, i) => v === sorted1[i])
}
