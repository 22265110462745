import { countryCurrencyMap } from '@/js/const';

// base = USD
export const convertCurrencyToUSD = ({ val = null, from = null, year = null }) => {
  if (!val && !from && !year)
    throw new Error("Invalid parameters convertCurrencyToUSD()");
  if (from === "USD") return Number(val);
  const exchangeRate = require(`@/assets/exchange-rate/${year}.json`);
  const rate = exchangeRate.rates[from];
  return Math.round(val / rate);
}

export const convertCountryCurrencyToUSD = ({ val = null, country = null, year = null }) => {
  if (!val && !country && !year)
    throw new Error("Invalid parameters convertCountryCurrencyToUSD()");
  if (country === "USA") return Number(val);
  const countryCurrency = countryCurrencyMap[country];
  const exchangeRate = require(`@/assets/exchange-rate/${year}.json`);
  const rate = exchangeRate.rates[countryCurrency];
  return Number(Math.round(val / rate));
}

