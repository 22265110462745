export default {
    methods: {
        convDL(val) {
            if (!val) {
                return "";
            }
            val = val.replace(/\r?\n/g, "");
            return val;
        },
        createCSV(db, filename) {
            var csv = "";
            db.forEach(el => {
                var line = "";
                line =
                    this.convDL(el["modelyear"]) +
                    "\t" +
                    this.convDL(el["category"]) +
                    "\t" +
                    this.convDL(el["maker"]) +
                    "\t" +
                    this.convDL(el["modelname"]) +
                    "\t" +
                    this.convDL(el["nickname"]) +
                    "\t" +
                    this.convDL(el["class"]) +
                    "\t" +
                    this.convDL(el["rank"]) +
                    "\t" +
                    this.convDL(el["cc"]) +
                    "\t" +
                    this.convDL(el["destination"]) +
                    "\t" +
                    this.convDL(el["releasedate"]) +
                    "\t" +
                    this.convDL(el["style"]) +
                    "\t" +
                    this.convDL(el["cg"]) +
                    "\t" +
                    this.convDL(el["eg"]) +
                    "\t" +
                    this.convDL(el["bd"]) +
                    "\t" +
                    this.convDL(el["el"]) +
                    "\t" +
                    this.convDL(el["memo"]) +
                    "\t" +
                    this.convDL(el["model"]) +
                    "\t" +
                    this.convDL(el["plannednumber"]) +
                    "\t" +
                    this.convDL(el["c_type"]) +
                    "\t" +
                    this.convDL(el["price"]) +
                    "\t" +
                    this.convDL(el["deadline"]) +
                    "\t" +
                    this.convDL(el["note"]) +
                    "\t" +
                    this.convDL(el["link"]) +
                    "\t" +
                    this.convDL(el["modelgroup"]) +
                    "\t" +
                    this.convDL(el["group"]) +
                    "\n";
                csv += line;
            });
            let blob = new Blob([csv], {
                type: "text/plain"
            });
            let link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            if (!filename) {
                filename = "Result";
            }
            link.download = filename + new Date().getTime() + ".tsv";
            link.click();
        }
    }
}