import { ActionTree, MutationTree, GetterTree } from "vuex";
import { RootState } from "@/store";
import { localStorage } from "@/plugins/local-storage";

const state = () => ({
  selectedUnitOfValueId: 0,
  units: [
    { value: "1", name: "設定しない", id: 0 },
    { value: "千", name: "千", id: 1 },
    { value: "万", name: "万", id: 2 },
  ],
  showTableGraph: true,
  labelFontSize: 12,
  axisFontSize: 12,
});

type State = ReturnType<typeof state>;

const getters: GetterTree<State, any> = {
  units: ({ units }) => units,
  selectedUnit: ({ selectedUnitOfValueId, units }) =>
    units.find((unit) => unit.id === selectedUnitOfValueId),
  formatter: ({ selectedUnitOfValueId }) => (value: number) => {
    if (selectedUnitOfValueId === 0) {
      return value;
    } else if (selectedUnitOfValueId === 1 /** 千 */) {
      return value / 1000;
    } else if (selectedUnitOfValueId === 2 /** 万 */) {
      return value / 10000;
    } else {
      return 0;
    }
  },
  showTableGraph: (state) => state.showTableGraph,
  labelFontSize: (state) => state.labelFontSize,
  axisFontSize: (state) => state.axisFontSize,
};

const actions: ActionTree<State, RootState> = {
  selectUnit({ commit }, id) {
    commit("selectUnit", id);
  },
  updateShowTableGraph({ commit }, boolean) {
    commit("updateShowTableGraph", boolean);
  },
  updateLabelFontSize({ commit }, value) {
    commit("updateLabelFontSize", value);
  },
  updateAxisFontSize({ commit }, value) {
    commit("updateAxisFontSize", value);
  },
};

const mutations: MutationTree<State> = {
  selectUnit(state, id) {
    state.selectedUnitOfValueId = id;
    localStorage.set("viewSettings", state);
  },
  updateShowTableGraph(state, boolean) {
    state.showTableGraph = boolean;
    localStorage.set("viewSettings", state);
  },
  updateLabelFontSize(state, value) {
    state.labelFontSize = value;
    localStorage.set("viewSettings", state);
  },
  updateAxisFontSize(state, value) {
    state.axisFontSize = value;
    localStorage.set("viewSettings", state);
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
