import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import ja from 'vuetify/es5/locale/ja';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: '#4e6da0',
        success: '#4d9f3a',
        accent: '#274277',
        danger: '#F44336'
      },
    },
  },
  lang: {
    locales: {
      ja
    },
    current: 'ja',
  },
});
