<template>
  <v-row>
    <v-col cols="6">
      <v-card flat>
        <v-card-title class="orange">
          総データ数:
          <span class="font-weight-black headline white--text">{{
            this.postPas.length
          }}</span>
        </v-card-title>
      </v-card>
    </v-col>
    <v-col cols="6">
      <v-card flat>
        <v-card-title class="red accent-2">
          モデル名数:
          <span class="font-weight-black headline white--text">{{
            this.modelcount
          }}</span>
        </v-card-title>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
export default {
  name: "BaseBadges",
  props: {
    postPas: {
      type: Array,
    },
    postMydb: {
      type: Function,
    },
  },
  computed: {
    modelcount() {
      if (!this.postMydb) {
        return 0;
      }
      return this.postMydb().distinct("modelname").length;
    },
  },
};
</script>
