import { FutureData, RawFutureData } from '@/assets/types/FutureData'
import { convertCountryCurrencyToUSD } from '@/js/convert-currency'
import moment from 'moment'


export type Data = {
  id: number
  country: string
  year: number
  maker: string
  model: string
  cc: number
  kw?: string
  category: string
  w_category: string
  f_category: string
  jan: number;
  feb: number;
  mar: number;
  apr: number;
  may: number;
  jun: number;
  jul: number;
  aug: number;
  sep: number;
  oct: number;
  nov: number;
  dec: number;
  price: number
  total: number
} // & Partial<FutureData>

export namespace Data {
  export const Months = [
    "jan",
    "feb",
    "mar",
    "apr",
    "may",
    "jun",
    "jul",
    "aug",
    "sep",
    "oct",
    "nov",
    "dec",
  ] as const;
  export type Formatted = {
    id: number;
    year: number; //あった方が何かと便利なので入れておく
    month: number; // dateは遅いのでmonthも入れておく
    date: moment.Moment;
    country: string;
    maker: string;
    model: string;
    cc: number;
    category: string;
    w_category: string;
    price: number;
    value: number;
  };

  export type Model = {
    maker: string;
    cc: number;
    price: number;
    w_category: string;
  };

  export type ModelEditHistoryItem = {
    origin: Model;
    new: Model;
  };

  export type ModelEditHistory = Record<string, ModelEditHistoryItem>;

  // Map<Data.Formatter>の方が良い気がするけど置いておく
  export const format: (data: Data) => Data.Formatted[] = (data) => {
    const { id, year, maker, cc, model, category, w_category, country, price } = data;

    return Data.Months.map((month, i) => ({
      id,
      year,
      month: i,
      date: moment(Date.UTC(year, i)),
      country,
      maker,
      model,
      cc,
      category,
      w_category,
      price,
      value: Number(data[month]),
    }))
  }

  export const normalize = (d: Data | FutureData, convertCurrency: boolean) => {
    d.year = Number(d.year)
    d.cc = Number(d.cc)
    d.w_category =
      d.w_category === '' || d.w_category === null || d.w_category === undefined
        ? '!empty!'
        : d.w_category
    d.price = convertCurrency
      ? convertCountryCurrencyToUSD({
          val: d.price,
          country: d.country,
          year: d.year,
        })
      : Number(d.price)
    if (isFutureData(d)) {
      d.future = !!Number(d.future)
      d.ev_flag = !!Number(d.ev_flag)
    }

    Object.freeze(d)
  }
}

function isFutureData(data: Data | FutureData): data is FutureData {
  return Boolean((data as any).f_category)
}
