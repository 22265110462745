import { Data } from "@/assets/types/Data";
import { FutureData } from '@/assets/types/FutureData';
import { StoreQueryType } from "@/assets/types/StoreQueryType";
import * as d3 from "d3";

export const filterData = (
  data: FutureData[],
  { future, maker, w_category, model, cc, price, showCcNull, showPriceNull, showCc, showEv }: StoreQueryType,
  options: StoreQueryType,
) => {
  const temp = (query: any[], options: any[]) => {
    let newQuery = query;
    let checkIsInClude = true;
    if (query && query.length > options.length / 2) {
      newQuery = options.filter((v) => !query.includes(v));
      checkIsInClude = false;
    }
    return { query: newQuery, checkIsInClude };
  };
  const makerQuery = temp(maker, options.maker);
  const wCategoryQuery = temp(w_category, options.w_category);
  const modelQuery = temp(model, options.model);
  const result = d3.filter(
    data,
    (d) =>
      evCheck(d, showCc, showEv) &&
      check(d.maker, makerQuery.query, options.maker, makerQuery.checkIsInClude) &&
      check(future ? d.f_category:  d.w_category, wCategoryQuery.query, options.w_category, wCategoryQuery.checkIsInClude) &&
      check(d.model, modelQuery.query, options.model, modelQuery.checkIsInClude) &&
      checkCheckBox(d.cc, cc, showCcNull) &&
      checkCheckBox(d.price, price, showPriceNull),
  );
  return result
};

const evCheck = (d: FutureData, showCc: boolean, showEv: boolean): boolean => {
  if (d.ev_flag) {
    return showEv !== false
  } else {
    return showCc !== false
  }
}

const check = (
  maker: string,
  query: StoreQueryType["maker" | "w_category" | "model"],
  options: StoreQueryType["maker" | "w_category" | "model"],
  checkIn: boolean,
) => {
  if (!query || query.length === 0 || query.length === options.length) {
    return true;
  }
  if (checkIn) {
    return query.includes(maker);
  } else {
    return !query.includes(maker);
  }
};

// ccとpriceのRangeは合成したいけど一旦置く。
const checkCheckBox = (cc: number | undefined, query: StoreQueryType["cc"], showNull: boolean = true) => {
  if (!cc) {
    return showNull;
  }
  if (!query || query.length === 0) {
    return true;
  }
  const ranges = query.filter((cc) => cc.checked !== false).map((v) => [v.from ?? 0, v.to ?? 999999999]);
  return ranges.some(([min, max]) => cc >= min && cc <= max);
};
