<template>
  <div id="basecharttimeline" class="white">
    <v-row class="py-0">
      <v-col>
        <v-switch v-model="isColor" label="開発区分、ランクで色分け"></v-switch>
        <v-btn
          text
          @click="clearCondition"
          v-show="isCondition"
          class="red--text"
        >
          検索条件クリア
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-col>
      <v-col cols="6" class="ml-auto">
        <v-text-field
          v-model.trim="search"
          append-icon="mdi-magnify"
          label="モデル名検索"
          single-line
          clearable
          hide-details
          class="mr-6"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="d-flex" cols="12" sm="2">
        <v-select
          :items="makers"
          v-model="maker"
          label="メーカー"
          clearable
        ></v-select>
      </v-col>
      <v-col class="d-flex" cols="12" sm="2">
        <v-select
          :items="destinations"
          v-model="destination"
          label="仕向地"
          clearable
        ></v-select>
      </v-col>
      <v-col class="d-flex" cols="12" sm="2">
        <v-select
          :items="categorise"
          v-model="category"
          label="カテゴリ"
          clearable
        ></v-select>
      </v-col>
      <v-col cols="12" sm="3">
        <v-row class="py-0">
          <v-col class="py-0 pr-0">
            <v-menu
              v-model="menu1"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  prepend-icon="mdi-chevron-left"
                  append-outer-icon="mdi-chevron-right"
                  :value="date_from"
                  label="開始日付"
                  prepend-inner-icon="mdi-calendar"
                  v-on="on"
                  @click:prepend="beforeDates1"
                  @click:append-outer="appendDates1"
                  readonly
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="date_from"
                type="month"
                :min="date_min"
                :max="date_end"
                @input="menu1 = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col class="py-0 pl-0">
            <v-menu
              v-model="menu2"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  prepend-icon="mdi-chevron-left"
                  append-outer-icon="mdi-chevron-right"
                  :value="date_end"
                  label="終了日付"
                  prepend-inner-icon="mdi-calendar"
                  v-on="on"
                  @click:prepend="beforeDates2"
                  @click:append-outer="appendDates2"
                  readonly
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="date_end"
                type="month"
                :min="date_from"
                :max="date_max"
                @input="menu2 = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" sm="1">
        <v-btn text @click="downloadCSV">データダウンロード</v-btn>
      </v-col>
      <v-col class="d-flex" cols="12" sm="2">
        <v-card flat>
          <v-card-title
            class="subtitle-1"
            :class="this.pas.length > 2000 ? 'red' : 'blue-grey darken-3'"
          >
            現在のデータ数:
            <span class="font-weight-black headline white--text">
              {{ this.pas.length }}
              <template v-if="this.rowcnt > 0">({{ this.rowcnt }})</template>
            </span>
            <br />
            <span v-if="this.pas.length > 2000"
              >データ数が2000以下に条件設定お願いします。</span
            >
          </v-card-title>
        </v-card>
      </v-col>
    </v-row>
    <div id="timelinechart" v-show="showTimeLine"></div>
    <v-card class="d-flex flex-row-reverse" flat>
      <v-card class="mr-4" flat>
        {{ dateRangeText }}
        <v-btn
          text
          icon
          @click="beforeDateFrom"
          :disabled="date_from < date_min"
        >
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
        <v-btn
          text
          icon
          @click="appendDateEnd"
          :disabled="date_end >= date_max"
        >
          <v-icon>mdi-chevron-right</v-icon>
        </v-btn>
      </v-card>
    </v-card>
    <transition>
      <div id="google-visualization-tooltip" v-show="showTool"></div>
    </transition>
  </div>
</template>
<script>
// TODO 色分け(カラーによって)
// 詳細に仕立てる
// データダウンロード
import { GoogleCharts } from "google-charts";
import moment from "moment";
import DL from "@/mixins/download";
export default {
  name: "Timeline",
  mixins: [DL],
  data: () => ({
    pageX: 0,
    pageY: 0,
    clientY: 0,
    waittime: 100,
    waitcnt: 0,
    menu1: false,
    menu2: false,
    myload: true,
    isColor: true,
    width: window.innerWidth,
    height: window.innerHeight,
    chart_height: 0,
    date_max: moment().format("YYYY-MM"),
    date_min: "2006-01",
    date_from: "2021-01",
    date_end: moment().format("YYYY-MM"),
    search: "",
    rowcnt: 0,
    cnt: 0,
    pas: [],
    showTimeLine: true,
    showTool: false,
    chart: null,
    data: [
      [
        { type: "string", id: "Position" },
        { type: "string", id: "Name" },
        {
          type: "string",
          role: "tooltip",
          p: { html: true },
        },
        { type: "date", id: "Start" },
        { type: "date", id: "End" },
        { role: "style" },
      ],
    ],
    idx: [],
    chartOptions: {
      timeline: {
        rowLabelStyle: { fontName: "Meiryo" },
        barLabelStyle: { fontName: "Meiryo" },
      },
      height: 300,
    },
    category: null,
    categorise: [
      "Sport",
      "Scooter",
      "Cruiser",
      "Off/Compe",
      "Dual purpose",
      "Moped",
      "A-2UP Utility",
      "A-Sports",
      "A-Utility",
      "A-Youth",
      "R-Multipurpose",
      "R-Recreation",
      "R-Sports",
      "R-Utility",
      "R-Youth",
    ],
    maker: null,
    makers: [
      "HONDA",
      "KAWASAKI",
      "SUZUKI",
      "ARCTIC CAT",
      "BAJAJ",
      "BMW",
      "BOMBARDIER",
      "DUCATI",
      "HARLEY DAVIDSON",
      "HERO",
      "JOHN DEERE",
      "KTM",
      "KYMCO",
      "MODENAS",
      "PIAGGIO",
      "POLARIS",
      "SYM",
      "TEXTRON",
      "TRIUMPH",
      "VICTORY",
      "YAMAHA",
    ],
    destination: null,
    destinations: [
      "USA",
      "ITA",
      "JPN",
      "CHN",
      "IND",
      "BRA",
      "COL",
      "DEU",
      "FRA",
      "IDN",
      "MYS",
      "PHL",
      "THA",
      "TWN",
      "VNM",
    ],
  }),
  props: {
    postPas: {
      type: Array,
    },
    postMydb: {
      type: Function,
    },
  },
  watch: {
    postMydb: function (val) {
      (() => {
        if (!this.myload) {
          return;
        }
        const con = JSON.parse(window.localStorage.getItem("basechartcon"));
        this.myload = false;
        if (!con) {
          return;
        }
        this.date_from = con.date_from;
        this.date_end = con.date_end;
        this.search = con.search;
        this.category = con.category;
        this.maker = con.maker;
        this.destination = con.destination;
      })();
      this.unasyncGetData(val);
    },
    destination: function () {
      this.unasyncGetData(this.postMydb);
    },
    category: function () {
      this.unasyncGetData(this.postMydb);
    },
    search: function () {
      this.unasyncGetData(this.postMydb);
    },
    maker: function () {
      this.unasyncGetData(this.postMydb);
    },
    date_from: function () {
      if (this.date_from < this.date_end) {
        this.unasyncGetData(this.postMydb);
      }
    },
    date_end: function () {
      if (this.date_from < this.date_end) {
        this.unasyncGetData(this.postMydb);
      }
    },
    isColor: function () {
      if (this.pas.length === 0 || this.pas.length > 2000) {
        return;
      }
      this.draw();
    },
  },
  created() {
    GoogleCharts.load(this.loadChart, { packages: ["timeline"] });
    document.addEventListener("mousemove", this.onMouseUpdate, false);
  },
  mounted() {
    window.addEventListener("resize", this.handleResize);
    // this.max_date = moment().format("YYYY-MM")
    this.handleResize();
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.handleResize);
  },
  computed: {
    dateRangeText() {
      const date1 = moment(this.date_from + "-01"),
        date2 = moment(this.date_end + "-01");

      return (
        this.date_from +
        " ~ " +
        this.date_end +
        " (" +
        date2.diff(date1, "months") +
        "ヵ月)"
      );
    },
    isCondition() {
      if (
        this.date_from !== "2020-01" ||
        this.date_end !== this.date_max ||
        this.search ||
        this.category ||
        this.maker ||
        this.destination
      ) {
        return true;
      }
      return false;
    },
  },
  methods: {
    clearCondition() {
      this.date_from = "2020-01";
      this.date_end = this.date_max;
      this.search = null;
      this.category = null;
      this.maker = null;
      this.destination = null;
      return false;
    },
    beforeDateFrom() {
      const date1 = moment(this.date_from + "-01"),
        date2 = moment(this.date_end + "-01"),
        new_date2 = moment(this.date_from + "-01"),
        date_min = moment(this.date_min + "-01");
      new_date2.subtract(date2.diff(new_date2, "months"), "months");
      if (new_date2.isBefore(date_min)) {
        return;
      }
      this.date_end = date1.format("YYYY-MM");
      this.date_from = date1
        .subtract(date2.diff(date1, "months"), "months")
        .format("YYYY-MM");
    },
    appendDateEnd() {
      const date1 = moment(this.date_from + "-01"),
        date2 = moment(this.date_end + "-01"),
        new_date1 = moment(this.date_end + "-01"),
        date_max = moment(this.date_max + "-01");
      new_date1.add(new_date1.diff(date1, "months"), "months");
      if (new_date1.isAfter(date_max)) {
        return;
      }
      this.date_from = date2.format("YYYY-MM");
      this.date_end = date2
        .add(date2.diff(date1, "months"), "months")
        .format("YYYY-MM");
    },
    beforeDates1() {
      const date1 = moment(this.date_from + "-01"),
        date_min = moment(this.date_min + "-01");
      date1.subtract(1, "months");
      if (date1.isSameOrAfter(date_min)) {
        this.date_from = date1.format("YYYY-MM");
      }
    },
    beforeDates2() {
      const date1 = moment(this.date_end + "-01"),
        date_min = moment(this.date_from + "-01");
      date1.subtract(1, "months");
      if (date1.isSameOrAfter(date_min)) {
        this.date_end = date1.format("YYYY-MM");
      }
    },
    appendDates1() {
      const date1 = moment(this.date_from + "-01"),
        date_max = moment(this.date_end + "-01");
      date1.add(1, "months");
      if (date1.isSameOrBefore(date_max)) {
        this.date_from = date1.format("YYYY-MM");
      }
    },
    appendDates2() {
      const date1 = moment(this.date_end + "-01"),
        date_max = moment(this.date_max + "-01");
      date1.add(1, "months");
      if (date1.isSameOrBefore(date_max)) {
        this.date_end = date1.format("YYYY-MM");
      }
    },
    unasyncGetData(db) {
      this.waitcnt++;
      const mycnt = this.waitcnt;
      window.setTimeout(() => {
        if (mycnt === this.waitcnt) {
          this.waitcnt = 0;
          this.getData(db);
        }
      }, this.waittime);
    },
    handleResize() {
      this.width = document.getElementById("basecharttimeline").clientWidth;
      this.height = window.innerHeight;
      if (this.myload) {
        return;
      }
      this.draw();
    },
    stringLeft(str, len) {
      if (!str) {
        return "";
      }
      const str_length = str.length;
      if (len >= str_length) {
        return str;
      }
      return str.substr(0, len) + "...";
    },
    selectHandler() {
      var selection = this.chart.getSelection();
      if (selection.length > 0) {
        const data = this.pas[this.idx[selection[0].row]];
        const relationlinks = [];
        this.postMydb({
          modelgroup: data.modelgroup,
          destination: data.destination,
        })
          .order("releasedate desc")
          .each((r) => {
            relationlinks.push(r);
          });
        window.localStorage.setItem(
          "relation_" + data.id,
          JSON.stringify(relationlinks)
        );
        const resolvedRoute = this.$router.resolve({
          name: "ModelClass",
          query: { id: data.id, r_id: data.id },
        });
        window.open(resolvedRoute.href);
      }
    },
    setChart(chart, google) {
      this.chart = chart;
      google.visualization.events.addListener(
        chart,
        "select",
        this.selectHandler
      );
    },
    loadChart() {
      var chart = new GoogleCharts.api.visualization.Timeline(
        document.getElementById("timelinechart")
      );
      this.chart = chart;
      GoogleCharts.api.visualization.events.addListener(
        chart,
        "select",
        this.selectHandler
      );
      GoogleCharts.api.visualization.events.addListener(
        chart,
        "onmouseover",
        () => {
          window.setTimeout(() => {
            this.onMouse();
          }, 50);
        }
      );
      GoogleCharts.api.visualization.events.addListener(
        chart,
        "onmouseout",
        () => {
          this.showTool = false;
        }
      );
    },
    drawChart() {
      if (!this.chart) {
        window.setTimeout(() => {
          this.drawChart();
        }, 500);
        return;
      }
      this.writeCon();
      this.draw();
    },
    draw() {
      var dataTable = new GoogleCharts.api.visualization.DataTable();
      dataTable.addColumn({ type: "string", id: "Position" });
      dataTable.addColumn({ type: "string", id: "Name" });
      dataTable.addColumn({
        type: "string",
        role: "tooltip",
        p: { html: true },
      });
      if (this.isColor) {
        dataTable.addColumn({ type: "string", role: "style" });
      } else {
        dataTable.addColumn({ type: "string", role: "hoo" });
      }
      dataTable.addColumn({ type: "date", id: "Start" });
      dataTable.addColumn({ type: "date", id: "End" });
      dataTable.addRows(this.data);

      this.chartOptions.height = this.height > 600 ? this.height - 300 : 300;
      this.chart.draw(dataTable, this.chartOptions);
    },
    createRow(row_n, row, pas_idx) {
      const createTr = (val, label) => {
          if (!val) {
            return "";
          }
          return `<tr>
            <td width="130px">${label}</td>
            <td><b>${val}</b></td>
            </tr>`;
        },
        createCustomHTMLContent = (row) => {
          var str = '<div style="width:360px;padding:5px 5px 5px 5px;">';
          str += "<table>";
          str += createTr(row.category, "開発カテゴリ");
          str += createTr(row.maker, "メーカー");
          str += createTr(row.modelname, "モデル名");
          str += createTr(row.destination, "仕向地");
          str += createTr(row.releasedate, "立上年月/発売時期");
          str += createTr(row.modelgroup, "グループ");
          str += createTr(row.class, "開発区分");
          str += createTr(row.rank, "開発ランク");
          // str += createTr(row.eg, "E/G");
          // str += createTr(row.bd, "B/D");
          str += "</table>";
          str += "</div>";
          return str;
        },
        editClassRank = (item) => {
          if (!item.class && !item.rank) {
            return "#" + "@" + item.modelyear + "_" + item.modelname;
          }
          if (!item.class) {
            return item.rank + "@" + item.modelyear + "_" + item.modelname;
          }
          if (!item.rank) {
            return item.class + "@" + item.modelyear + "_" + item.modelname;
          }
          return (
            item.class +
            ":" +
            item.rank +
            "@" +
            item.modelyear +
            "_" +
            item.modelname
          );
        },
        editDate = (val) => {
          return new moment(val.substr(0, 4) + "-" + val.substr(4, 2) + "-01")
            .endOf("month")
            .toDate();
        },
        mystartDate = (val) => {
          return new moment(
            val.substr(0, 4) + "-" + val.substr(4, 2) + "-01"
          ).toDate();
        },
        calDeadline = (r, rn) => {
          const max_date = new moment(this.date_end + "-01")
            .endOf("month")
            .toDate();
          if (
            r.deadline &&
            r.deadline !== "999999" &&
            parseInt(r.releasedate, 10) < parseInt(r.deadline, 10)
          ) {
            if (max_date > new Date(editDate(r.deadline))) {
              return editDate(r.deadline);
            } else {
              return max_date;
            }
          }
          if (rn) {
            if (
              rn.modelgroup !== r.modelgroup ||
              rn.destination !== r.destination
            ) {
              return max_date;
            }
            if (rn.releasedate > r.releasedate) {
              return editDate(rn.releasedate);
            }
            for (let x = pas_idx + 2; x < this.pas.length; x++) {
              if (
                this.pas[x].modelgroup !== r.modelgroup ||
                this.pas[x].destination !== r.destination
              ) {
                return max_date;
              }
              if (this.pas[x].releasedate > r.releasedate) {
                return editDate(this.pas[x].releasedate);
              }
            }
          }
          return max_date;
        };
      let deadline = calDeadline(row, row_n);
      const min = this.date_from.replace("-", "");
      const release_date = row.releasedate > min ? row.releasedate : min;
      let rtn = [
        this.stringLeft(row.modelgroup, 10) + "_" + row.destination,
        editClassRank(row),
        createCustomHTMLContent(row),
        this.pushColors(row.class, row.rank),
        mystartDate(release_date),
        deadline,
      ];
      // this.pushColors(row.class, row.rank);
      return rtn;
    },
    pushColors(myclass, rank) {
      var color = "#03A9F4";
      switch (myclass) {
        case "":
          switch (rank) {
            case ("AA", "A"):
              color = "#01579B";
              break;
            case "B":
              color = "#0288D1";
              break;
            case "C":
              break;
            case "D":
              color = "#4FC3F7";
              break;
            default:
              color = "#E1F5FE";
              break;
          }
          break;
        case "NEW":
          switch (rank) {
            case ("AA", "A"):
              color = "#B71C1C";
              break;
            case "B":
              color = "#D32F2F";
              break;
            case "C":
              color = "#F44336";
              break;
            case "D":
              color = "#E57373";
              break;
            default:
              color = "#FFEBEE";
              break;
          }
          break;
        case "MOC":
          switch (rank) {
            case ("AA", "A"):
              color = "#311B92";
              break;
            case "B":
              color = "#512DA8";
              break;
            case "C":
              color = "#673AB7";
              break;
            case "D":
              color = "#B39DDB";
              break;
            default:
              color = "#EDE7F6";
              break;
          }
          break;
        case "VAR":
          switch (rank) {
            case ("AA", "A"):
              color = "#BF360C";
              break;
            case "B":
              color = "#E64A19";
              break;
            case "C":
              color = "#FF5722";
              break;
            case "D":
              color = "#FF8A65";
              break;
            default:
              color = "#FFCCBC";
              break;
          }
          break;
        case "MIC":
          switch (rank) {
            case ("AA", "A"):
              color = "#1B5E20";
              break;
            case "B":
              color = "#388E3C";
              break;
            case "C":
              color = "#4CAF50";
              break;
            case "D":
              color = "#A5D6A7";
              break;
            default:
              color = "#E8F5E9";
              break;
          }
          break;
        default:
          switch (rank) {
            case ("AA", "A"):
              color = "#263238";
              break;
            case "B":
              color = "#455A64";
              break;
            case "C":
              color = "#607D8B";
              break;
            case "D":
              color = "#90A4AE";
              break;
            default:
              color = "#ECEFF1";
              break;
          }
          break;
      }
      return color;
      // this.colors.push(color);
    },
    writeCon() {
      var con = {};
      if (this.myload) {
        return;
      }
      con.date_from = this.date_from;
      con.date_end = this.date_end;
      con.search = this.search;
      con.category = this.category;
      con.maker = this.maker;
      con.destination = this.destination;
      window.localStorage.setItem("basechartcon", JSON.stringify(con));
    },
    downloadCSV() {
      this.createCSV(this.pas, "timeline");
    },
    createBeforeMin(rn, r, idx) {
      if (!rn) {
        return;
      }
      if (rn.destination !== r.destination) {
        return;
      }
      if (
        rn.releasedate <= this.date_from.replace("-", "") &&
        rn.modelgroup === r.modelgroup
      ) {
        return;
      }
      this.idx.push(idx);
      this.data.push(this.createRow(rn, r));
    },
    onMouse() {
      var tooltip = document.querySelector("div.google-visualization-tooltip");
      var tooltipcopy = document.getElementById("google-visualization-tooltip");
      if (tooltip && tooltip.innerHTML) {
        tooltipcopy.innerHTML = tooltip.innerHTML;
      } else {
        tooltipcopy.innerHTML = "";
        return;
      }
      //前々回のバージョン：特にマウス位置は考慮しない
      var yPos = 0;
      yPos = this.pageY - 20; //下半分にいたらカーソル位置の上にツールチップを表示
      if (0 > window.innerHeight - this.clientY - 190) {
        yPos = this.pageY - 150;
      }
      // console.log(
      //   window.innerHeight,
      //   this.clientY,
      //   tooltip.getBoundingClientRect().height,
      //   this.pageY - tooltip.getBoundingClientRect().height - 10,
      //   yPos
      // );
      var xPos = this.pageX;
      if (window.innerWidth / 2 > xPos) {
        xPos -= 370;
      }
      // console.log(this.pageX, xPos, window.innerWidth, tooltip.style.left);
      tooltipcopy.style.top = yPos + "px";
      // tooltipcopy.style.left = tooltip.style.left;
      tooltipcopy.style.left = xPos + "px";
      this.showTool = true;
      // window.setTimeout(() => {
      //   console.log(tooltipcopy.clientHeight);
      // }, 1000);
    },
    getData(db) {
      //dataclear

      this.pas = [];
      this.idx = [];
      this.rowcnt = 0;

      this.data = [];
      var before_oneyear = moment(this.date_from + "-01").subtract(2, "years");
      var condition = {
        releasedate: {
          gte: before_oneyear.format("YYYYMM"),
          lte: this.date_end.replace("-", ""),
        },
        deadline: {
          gt: this.date_from.replace("-", ""),
        },
      };
      if (this.category) {
        condition.category = this.category;
      }
      if (this.search) {
        condition.modelname = { likenocase: this.search };
      }
      if (this.maker) {
        condition.maker = this.maker;
      }
      if (this.destination) {
        condition.destination = this.destination;
      }
      db(condition)
        .order("maker asec,modelgroup asec,destination asec,releasedate asec")
        .each((r) => {
          this.pas.push(r);
        });
      if (this.pas.length === 0 || this.pas.length > 2000) {
        this.showTimeLine = false;
        return;
      }
      if (!this.showTimeLine) {
        this.showTimeLine = true;
        window.setTimeout(() => {
          this.getData(db);
        }, 200);
        return;
      }
      // this.showTimeLine = true;
      // this.handleResize();
      // window.setTimeout(() => this.handleResize(), 1000);
      this.rowcnt = db(condition).distinct("modelgroup", "destination").length;
      for (let i = 0; i < this.pas.length; i++) {
        if (this.pas[i].releasedate < this.date_from.replace("-", "")) {
          this.createBeforeMin(this.pas[i + 1], this.pas[i], i);
          continue;
        }
        this.idx.push(i);
        this.data.push(this.createRow(this.pas[i + 1], this.pas[i], i));
      }
      this.drawChart();
    },
    onMouseUpdate(e) {
      if (e.pageX) {
        this.pageX = e.pageX;
        // console.log(this.pageX, window.innerWidth);
      }
      if (e.pageY) {
        this.pageY = e.pageY;
        this.clientY = e.clientY;
      }
      // console.log(e.clientY, event.screenY, window.innerHeight);
    },
  },
};
</script>
<style >
#timelinechart .google-visualization-tooltip {
  display: none;
}
#google-visualization-tooltip {
  position: absolute;
  background: rgba(255, 255, 255, 0.75);
}
</style>
