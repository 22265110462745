import * as d3 from "d3";
import { makerColors, wCategoryColors } from "@/js/const";
import { GetterTree, MutationTree, ActionTree } from "vuex";
import { localStorage } from "@/plugins/local-storage";
import { RootState } from "@/store";

export const RESET_TEMP_COLORS = "RESET_COLORS";
type ColorData = {
  maker: { [key: string]: string };
  wCategory: { [key: string]: string };
  category?: { [key: string]: string };
  model: { [key: string]: string };
  modelApplyMakerColor: boolean;
};

const defaultColors = [
  "#5470c6",
  "#91cc75",
  "#fac858",
  "#ee6666",
  "#73c0de",
  "#3ba272",
  "#fc8452",
  "#9a60b4",
  "#ea7ccc",
];

const state = () => ({
  makers: {},
  wCategories: {},
  models: {},
  tempMakers: {},
  tempWCategories: {},
  tempModels: {},
  modelApplyMakerColor: false,
});

type State = ReturnType<typeof state>;

const someColor = (str: string) => defaultColors[strToInt(str) % defaultColors.length];
const strToInt = (str: string) => {
  let intStr = "";
  for (let i = 0; i < str.length; i += 1) {
    intStr += str.charCodeAt(i);
  }
  return Number(intStr);
};

const actions: ActionTree<State, RootState> = {
  setTempColor({ commit }, { key, color }) {
    commit("setTempColor", { key, color });
  },

  fetchTempColors({ commit }) {
    commit("fetchTempColors");
  },

  [RESET_TEMP_COLORS]({ commit }, { key }) {
    commit(RESET_TEMP_COLORS, { key });
  },

  setModelApplyMakerColor({ commit }, value) {
    commit("setModelApplyMakerColor", value);
  },

  init({ commit, dispatch }, colors: ColorData | undefined) {
    if (colors) {
      commit("init", colors);
    }
    dispatch("fetchTempColors");
  },

  async save({ commit }) {
    commit("save");
  },
};

const mutations: MutationTree<State> = {
  init(state, colors: ColorData) {
    state.makers = { ...colors.maker };
    state.wCategories = { ...colors.wCategory, ...colors.category };
    state.models = { ...colors.model };
    state.modelApplyMakerColor = colors.modelApplyMakerColor;
  },
  setTempColor(state, { key, color }) {
    let temp: keyof State;
    if (key === "model") {
      temp = "tempModels";
    } else if (key === "maker") {
      temp = "tempMakers";
    } else if (key === "wCategory") {
      temp = "tempWCategories";
    } else {
      return;
    }
    state[temp] = { ...state[temp], ...color };
  },

  [RESET_TEMP_COLORS](state, { key }) {
    if (key === "model") {
      state.tempModels = {};
    } else if (key === "maker") {
      state.tempMakers = {};
    } else if (key === "wCategory") {
      state.tempWCategories = {};
    }
  },

  save(state) {
    state.models = { ...state.tempModels };
    state.makers = { ...state.tempMakers };
    state.wCategories = { ...state.tempWCategories };
    const { modelApplyMakerColor } = state;
    const saveData: ColorData = {
      model: state.models,
      maker: state.makers,
      wCategory: state.wCategories,
      modelApplyMakerColor,
    };
    localStorage.set("colors", saveData);
  },

  fetchTempColors(state) {
    state.tempWCategories = { ...state.wCategories };
    state.tempModels = { ...state.models };
    state.tempMakers = { ...state.makers };
  },

  setModelApplyMakerColor(state, value) {
    state.modelApplyMakerColor = value;
  },
};

const getters: GetterTree<State, any> = {
  modelApplyMakerColor: ({ modelApplyMakerColor }) => modelApplyMakerColor,
  makers: ({ makers }, { defaultMakerColor }) => ({ ...defaultMakerColor, ...makers }),
  wCategories: ({ wCategories }, { defaultWCategoryColor }) => ({
    ...defaultWCategoryColor,
    ...wCategories,
  }),
  models: ({ modelApplyMakerColor, models }, getters) => {
    if (modelApplyMakerColor) {
      return getters._modelColorAppliedMakerColor;
    } else {
      return { ...getters.defaultModelColor, ...models };
    }
  },

  tempMakers: ({ tempMakers }, { defaultMakerColor }) => ({ ...defaultMakerColor, ...tempMakers }),
  tempWCategories: ({ tempWCategories }, { defaultWCategoryColor }) => ({
    ...defaultWCategoryColor,
    ...tempWCategories,
  }),
  tempModels: ({ modelApplyMakerColor, tempModels }, getters) => {
    if (modelApplyMakerColor) {
      return getters._tempModelColorAppliedMakerColor;
    } else {
      return { ...getters.defaultModelColor, ...tempModels };
    }
  },

  makerColorHasChanged: (state) => Object.keys(state.tempMakers).length > 0,
  wCategoryColorHasChanged: (state) => Object.keys(state.tempWCategories).length > 0,
  modelColorHasChanged: (state) => Object.keys(state.tempModels).length > 0,

  // ここからprivate
  defaultMakerColor: (_, __, ___, { manufacturers }) =>
    manufacturers
      .map((m) => ({ [m]: makerColors[m] ?? someColor(m) }))
      .reduce((pre, cur) => ({ ...pre, ...cur }), {}),
  defaultWCategoryColor: (_, __, ___, { wCategories }) =>
    wCategories
      .map((c) => ({ [c]: wCategoryColors[c] ?? someColor(c) }))
      .reduce((pre, cur) => ({ ...pre, ...cur }), {}),
  defaultModelColor: (_, __, ___, { models }) => {
    const array: { [key: string]: any } = {};
    for (let i = 0; i < models.length; i += 1) {
      const m = models[i];
      array[m] = someColor(m);
    }
    return array;
  },

  // TODO 同一モデルでメーカーが違う、ということはない？
  modelAndMakerPair: (_, __, { data }) =>
    d3.groups(data as any[], (d) => d.model).map(([model, [{ maker }]]) => ({ model, maker })),

  modelToMaker: (_, { modelAndMakerPair }) => {
    const assoc: { [key: string]: any } = {};
    for (let i = 0; i < modelAndMakerPair.length; i += 1) {
      const { model, maker } = modelAndMakerPair[i];
      assoc[model] = maker;
    }
    return assoc;
    // return modelAndMakerPair
    //   .map(({ model, maker }) => ({ [model]: maker }))
    //   .reduce((pre, cur) => ({ ...pre, ...cur }), {})
  },

  _modelColorAppliedMakerColor: (_, getters, __, { models }) => {
    const { modelToMaker } = getters;
    const re = {};
    models.forEach((model) => (re[model] = getters.makers[modelToMaker[model]]));
    return re;
  },

  _tempModelColorAppliedMakerColor: (_, getters, __, { models }) => {
    const { modelToMaker } = getters;
    const re = {};
    models.forEach((model) => (re[model] = getters.tempMakers[modelToMaker[model]]));
    return re;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
